<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-white">
    <body class="h-full">
    ```
  -->
    <div>

        <Sidebar></Sidebar>

        <main class="py-10 lg:pl-72">
            <div class="px-4 sm:px-6 lg:px-8">
                <Suspense>
                    <router-view></router-view>
                </Suspense>
            </div>
        </main>
    </div>
</template>

<script setup>

import Sidebar from "@/layout/Sidebar.vue";

console.log("process env", process.env.VUE_APP_API_URL)
</script>
