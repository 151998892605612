import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'DashBoard',
    component: () => import('../components/dashboard/Overview.vue'),
  },
  {
    path: '/milk',
    name: 'MilkEntries',
    component: () => import('../components/milk/MilkEntries.vue'),
  },
  {
    path: '/weight',
    name: 'WeightOverview',
    component: () => import('../components/weight/WeightOverview.vue'),
  },
  {
    path: '/openai',
    name: 'OpenAiInput',
    component: () => import('../components/openai/OpenaiInput.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
